import data from "./components/data"
import faqToggle from "./components/faqToggle"
import splide from "./components/splide"


document.addEventListener(
  'DOMContentLoaded',
  () => {
    data()
    faqToggle()
    splide()
  },
  false
)
