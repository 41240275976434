//@ts-nocheck
/**
 * Data chart
 */
declare var Chart : any

const data = () => {
  const chart = document.querySelector('.js-data')

  if(chart) {
    const container = chart.closest('.js-anima')

    const data = {
      datasets: [
        {
          data: [34.5, 27.6, 25.9, 9.2, 1.6],
          backgroundColor: [
            '#FE8700',
            '#FFB92C',
            '#FFDF88',
            '#FFF8D6',
            '#ECECEC',
          ],
          borderWidth: 0,
        },
      ],
    }

    var donuthole = 110
    if(screen.width < 767) {
      donuthole = 50
    }

    const config = {
      type: 'doughnut',
      data: data,
      options: {
        cutout: donuthole,
        responsive: true,
        events: null,
        animation: {
          duration: 2000,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false
          },
        },
      },
    }

    let controller = new ScrollMagic.Controller()
    let scene = new ScrollMagic.Scene({
      triggerElement: container,
      reverse: false,
      triggerHook: 0.7,
    }).addTo(controller)
      // .addIndicators({name: "chart-scene"})

    scene.on('enter', function() {
      new Chart(chart, config)
    })
  }
}

export default data