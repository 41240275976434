/**
 * Top page splide
 */

declare var Splide: any

const splide = () => {
  const getcase = document.querySelector('.js-case')

  if(getcase) {
    var gallerySplider = new Splide(getcase, {
      type: 'slide',
      width: '340px',
      autoHeight: true,
      focus: 'center',
      rewind: 'true',
      gap: '40px',
      arrows : true,
      pagination : true,
      mediaQuery: 'min',
      breakpoints: {
        767: {
          destroy: true,
        },
      }
    });
  
    gallerySplider.mount();
  }
}

export default splide